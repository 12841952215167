import React from 'react';
import profile from './profile.png';
import './App.css';

function ExternalLink(props) {
    return (
        <a href={ props.to } target='_blank' rel='noopener noreferrer'>
            { props.text }
        </a>
    );
}

function CardHeader() {
    return (
        <div class='card-header animated fadeIn'></div>
    );
}

function CardProfile() {
    return (
        <div class='card-profile animated fadeIn'>
            <div class='card-profileImage'>
                <img src={ profile } alt='Profile' />
            </div>
        </div>
    );
}

function CardBody() {
    return (
        <div class='card-text animated fadeIn'>
            <h1 class='card-title' lang='tr'>Gülfem Demir</h1>
            <p class='card-descr'>Hi! I am a computer engineer working at Seven Bridges Genomics in Cambridge, Massachusetts. I received my masters degree in Computer Science from Bilkent University in March 2017. I have been a graduate student at <ExternalLink to='http://donut.cs.bilkent.edu.tr' text='Alkan Lab' /> and teaching assistant at <ExternalLink to='http://ceng.metu.edu.tr' text='METU CENG' /> for two years. Prior to that, I have studied at METU and received BSc degree in Computer Engineering in 2014.</p>
            <p class='card-descr'>I love film festivals and enjoy traveling. Feel free to send me an <ExternalLink to='mailto:demirgulfem@gmail.com' text='email' /> or follow me on <ExternalLink to='https://twitter.com/gulfemdemir' text='Twitter' />.</p>
        </div>
    )
}

function Card() {
    return (
        <div class='landing-card animated fadeInDown'>
            <CardHeader />
            <CardProfile />
            <CardBody />
        </div>
    )
}

function Overlay() {
    return (
        <div class='landing-overlay'></div>
    )
}

function App() {
    return (
        <main class="landing-wrapper">
            <Overlay />
            <Card />
        </main>
    );
}

export default App;
